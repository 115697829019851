@use "global-color" as *;
@use "../shared/global" as *;

@mixin global-tokens {
    --g-color-black: #{$g-color-black};
    --g-color-white: #{$g-color-neutral-10};
    --g-color-ink-black: #{$g-color-neutral-900};

    --g-color-primary-500: #{$g-color-primary-500};

    --g-letter-spacing-standard: 0;
    --g-letter-spacing-loose: 1px;
    --g-letter-spacing-xloose: 2px;

    --g-font-size-100: 0.688rem;
    --g-font-size-200: 0.75rem;
    --g-font-size-300: 0.875rem;
    --g-font-size-400: 1rem;
    --g-font-size-500: 1.125rem;
    --g-font-size-600: 1.375rem;
    --g-font-size-700: 1.5rem;
    --g-font-size-800: 1.75rem;
    --g-font-size-900: 2rem;
    --g-font-size-1000: 2.25rem;
    --g-font-size-1100: 2.5rem;
    --g-font-size-1200: 3rem;
    --g-font-size-1300: 3.5rem;
    --g-font-size-1400: 3.75rem;
    --g-font-size-1500: 4.5rem;

    --g-line-height-100: 130%;
    --g-line-height-200: 130%;
    --g-line-height-300: 150%;
    --g-line-height-400: 150%;
    --g-line-height-500: 150%;
    --g-line-height-600: 140%;
    --g-line-height-700: 140%;
    --g-line-height-800: 130%;
    --g-line-height-900: 130%;
    --g-line-height-1000: 130%;
    --g-line-height-1100: 120%;
    --g-line-height-1200: 120%;
    --g-line-height-1300: 120%;
    --g-line-height-1400: 120%;
    --g-line-height-1500: 120%;

    --g-size-xxs: 20px;
    --g-size-xs: 24px;
    --g-size-sm: 40px;
    --g-size-md: 48px;
    --g-size-lg: 56px;
    --g-size-xl: 64px;
    --g-size-xxl: 72px;
    --g-size-xxxl: 80px;
    --g-size-xxxxl: 96px;

    --g-spacing-xxs: 2px;
    --g-spacing-xs: 4px;
    --g-spacing-sm: 8px;
    --g-spacing-md: 16px;
    --g-spacing-lg: 24px;
    --g-spacing-xl: 32px;
    --g-spacing-xxl: 64px;

    --g-border-width-xs: 1px;
    --g-border-width-sm: 2px;
    --g-border-width-md: 3px;
    --g-border-width-lg: 4px;
    --g-border-width-xl: 6px;

    --g-border-radius-xs: 4px;
    --g-border-radius-sm: 8px;
    --g-border-radius-md: 16px;
    --g-border-radius-lg: 24px;

    --g-opacity-faint: 0.1;
    --g-opacity-minimal: 0.15;
    --g-opacity-quiet: 0.5;
    --g-opacity-moderate: 0.7;
    --g-opacity-bold: 0.9;

    --g-duration-xshort: 0.3s;
    --g-duration-short: 0.5s;
    --g-duration-medium: 0.7s;
    --g-duration-long: 1s;
    --g-duration-xlong: 1.5s;

    --g-font-weight-light: 200;
    --g-font-weight-regular: 300;
    --g-font-weight-medium: 500;
    --g-font-weight-bold: 700;

    --g-paragraph-spacing-standard: 0;
    --g-paragraph-spacing-list: 0.5rem;

    :global {
        .visually-hidden {
            @include visually-hidden;
        }
    }
}

.visually-hidden {
    @include visually-hidden;
}
